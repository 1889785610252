'use client'
import { useEffect } from 'react';
import medallia from '~/components/Footer/utils/EncuestaMedallia';

export default function Medallia() {
  useEffect(() => {
    medallia.neb_AddEventListener(
      window,
      'neb_OnsiteLoaded',
      medallia.onsiteLoaded(window),
    );
  }, []);
}
