import { Link } from '#/renderer/Link';
import React from 'react';

export default function Destacados({ category }) {
  return (
    <div>
      <div className='seeAll'>
        <h2 style={{ display: 'inline' }}>{category.name}</h2>
        <Link to={`/categories/${category.referrer}`}>
          Ver todo <i className='fas fa-chevron-right' aria-hidden />
        </Link>
      </div>
    </div>
  );
};
