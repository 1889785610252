'use client'
import { useEffect, useState } from 'react';
import { productList } from '~/store/Catalog/Catalog';
import { ajusteDestacados } from '#/renderer/utils/home';
import Slider from 'react-slick';
//import blukai, removido
import { ArrowNext, ArrowPrev } from '~/helpers/svg';
import Destacados from '~/containers/Home/Destacados';
import ProductoDestacado from '~/containers/Home/ProductoDestacado';

const macroSlider = {
  infinite: false,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 6,
  arrows: true,
  prevArrow: <ArrowPrev />,
  nextArrow: <ArrowNext />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
        initialSlide: 2,
        arrows: true,
        infinite: true,
        centerMode: true,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        dots: false,
        arrows: true,
        initialSlide: 4,
      },
    },
  ],
};

const macroSliderResponsive = {
  infinite: true,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  arrows: true,
  prevArrow: <ArrowPrev />,
  nextArrow: <ArrowNext />,
  afterChange: (idx) => {
    document
      .querySelector(
        `.sliderTabsMenuHomeResponsive div[data-index="${idx}"] .stmElementHome`,
      )
      .click();
  },
};

const destSlider = {
  infinite: true,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 3,
  centerMode: true,
  arrows: true,
  dots: true,
  prevArrow: <ArrowPrev />,
  nextArrow: <ArrowNext />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0px',
        infinite: true,
        dots: true,
        arrows: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0px',
        infinite: true,
        dots: true,
        arrows: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        infinite: true,
        dots: true,
        initialSlide: 1,
        arrows: false,
        autoplay: true,
      },
    },
  ],
};

export default function Categories(page) {
  const { macro: category, config, esim: esimIndicator, isMobile } = page ?? {};
  const [active, setActive] = useState(category?.[0]);
  const [destList, setDestList] = useState([]);
  const [planes, setPlanes] = useState({});
  const [title, setTitle] = useState(
    config?.metatag_titulos_categorias?.[category?.[0]?.referrer]
      ?.tittle_home || '',
  );

  const onCategory = (cat) => async () => {
    setTitle(config.metatag_titulos_categorias[cat.referrer].tittle_home);
    setActive(cat);
    const res = await productList(cat.referrer, null, null, 1);
    if (res.success) {
      const data = ajusteDestacados(res.data, isMobile);
      setDestList(data);
      setPlanes(res.planes);
    }
  };

  useEffect(() => {
    if (category.length) {
      onCategory(category[0])();
    }
  }, []);

  const destListWithEsimValidation = destList.map((x) => {
    const hasAtLeadOneVariantWithAttributeEsim = x.items.some((item) => {
      const attrConfigEsim = item.attributes.find(
        (a) => a?.identifier === 'attr-conf-esim',
      );
      return attrConfigEsim?.value === 'SI';
    });

    return {
      ...x,
      hasVarianWithEsim: hasAtLeadOneVariantWithAttributeEsim,
      esimIndicator: hasAtLeadOneVariantWithAttributeEsim
        ? esimIndicator
        : null,
    };
  });

  return (
    <>
      <div className="text-center sliderTabsHome">
        <h1 className="roboto-medium services-title mt-3">{title}</h1>
        <div
          className={
            isMobile ? 'sliderTabsMenuHomeResponsive' : 'sliderTabsMenuHome'
          }
        >
          <Slider {...(isMobile ? macroSliderResponsive : macroSlider)}>
            {category.map((value, i) => (
              <div
                key={i}
                className={`stmElementHome ${
                  value.referrer === active?.referrer ? 'active' : ''
                }`}
                onClick={() => {
                  onCategory(value)();
                  //blukai function, removida
                }}
              >
                {value.name}
                {isMobile && <span className="barra" />}
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {active && <Destacados category={active} />}
      <div className="carouselDestacados">
        <Slider key={destListWithEsimValidation} {...destSlider}>
          {destListWithEsimValidation.map((prod, i) => (
            <ProductoDestacado
              key={prod.part_number + i}
              prod={prod}
              category={active}
              planes={planes}
              config={config}
            />
          ))}
        </Slider>
      </div>
    </>
  );
}
