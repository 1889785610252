'use client';
import Slider from 'react-slick';
import { ArrowNext, ArrowPrev } from '~/helpers/svg';

export default function HomeSlider({ settings, customPaging, children }) {
  if (customPaging) {
    settings.customPaging = () => customPaging;
  }
  settings.prevArrow = <ArrowPrev />;
  settings.nextArrow = <ArrowNext />;

  return <Slider {...settings}>{children}</Slider>;
}
